import * as React from "react";
import styled from "styled-components";
import BoldContentful from "../../../../utils/BoldContentful";

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  position: relative;

  @media (max-width: 900px) {
    height: auto;
    margin-top: 48px;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Textocontent = styled.div`
  color: var(--gris);
  font-weight: 400;
  font-size: 38px;
  width: 90%;
  text-align: left;
`;

const Textrichtasatitle = ({ texto, clr }) => {
  return (
    <Container>
      <Subcontainer>
        <Textocontent c={clr}>{BoldContentful(texto, clr)}</Textocontent>
      </Subcontainer>
    </Container>
  );
};
export default Textrichtasatitle;
