import * as React from "react";
import styled from "styled-components";
import Layout from "../../../../components/Layout/Layout.js";
import useContentful from "../../../../utils/useContentful.jsx";
import useContentful2 from "../../../../utils/useContentful2.jsx";
import { Link } from "gatsby";
import MetaTags from "react-meta-tags";
import Titlerich from "../../../../components/Titlerich.js";
import Sideimage from "../../../../components/proyectos/pais/Sideimage.js";
import Historianorich from "../../../../components/proyectos/pais/crecer/Historianorich.js";
import Slidercrecer from "../../../../components/proyectos/pais/crecer/Slidercrecer.js";
import Textrichtasa from "../../../../components/proyectos/pais/tasa/Textrichtasa.js";
import Textrichtasatitle from "../../../../components/proyectos/pais/tasa/Textrichtasatitle.js";
import Textrichcrecer from "../../../../components/proyectos/pais/crecer/Textrichcrecer.js";
import Logos from "../../../../components/proyectos/pais/tasa/Logos.js";
import "../../../../styles/global.css";
import "../../../../styles/breadcrums.css";
import { Helmet } from "react-helmet";
import Home from "../../../../images/Home/home.png";
import Alcance from "../../../../images/Home/alcance_naranja.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 78px;
`;

const ImgAlcance = styled.img`
  width: 120px;
  height: auto;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: center;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Contentbar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5%;
  img {
    width: 160px;
    height: 100px;
  }
`;

const Cardcontainer = styled.div`
  padding: 0px 3%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;

  img {
    width: 80%;
  }
  @media (max-width: 900px) {
    padding: 0px 0%;

    img {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const Titulo = styled.div`
  width: 90%;
  max-width: 1440px;
  font-size: 38px;
  color: var(--verde);
  font-weight: 700;
  padding: 0px 4%;
`;

const Texto = styled.div`
  width: 90%;
  max-width: 1440px;
  color: var(--gris);
  padding: 0px 4%;
`;

const Imgstyled = styled.img`
  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

const Banner = styled.img`
  position: center;
  width: 100%;
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const TasaEntry = "3XMQ7urUk2uHZ0ttRAjjpO";

const IndexPage = ({ location }) => {
  const { data: tasa, fetched } = useContentful2({ id: TasaEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  

  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>Aprendo con TASA</title>
            <meta name="description" content="Aprendo con TASA" />
            <meta name="robots" content="index,follow" />
            <link
              rel="canonical"
              href="https://www.aporta.org.pe/proyectos/pais/tasa/"
            />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/pais/tasa/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/pais/tasa/"
            />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                  {
                    "@type": "ListItem",
                    position: 3,
                    name: `Pais`,
                    item: `https://www.aporta.org.pe/proyectos/pais/`,
                  },
                  {
                    "@type": "ListItem",
                    position: 4,
                    name: `Aprendo con TASA`,
                    item: `https://www.aporta.org.pe/proyectos/pais/tasa`,
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              <br></br>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  Innovación Social País
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos/pais/tasa"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Aprendo con TASA
                </Link>
              </Breadcrumbs>
              <br></br>
              <Titlerich
                color={"var(--magenta"}
                colortext={"var(--negro)"}
                container={tasa.fields.tituloRich}
                colorbold={"var(--negro"}
              ></Titlerich>
              <br></br>
              <Sideimage
                image={tasa.fields.tasaRow1image.fields.file.url}
                colorb={"var(--magenta)"}
              >
                <Contentbar>{tasa.fields.tasaRow1text}</Contentbar>
              </Sideimage>
              <br></br>
              <Historianorich
                title={tasa.fields.tasaRow2title}
                texto={tasa.fields.tasaRow2text}
                fontc={"var(--verde)"}
                fontcb={"var(--verde)"}
              >
                <Cardcontainer>
                  <img
                    src={tasa.fields.tasaRow2image.fields.file.url}
                    alt="tasa"
                  />
                </Cardcontainer>
              </Historianorich>
              <br></br>
              <br></br>
              <ImgAlcance src={Alcance} alt="tasa_alcance" />
              <Textrichcrecer
                texto={tasa.fields.tasaRow3text}
                clr={"var(--naranja)"}
              ></Textrichcrecer>

              <Textrichtasatitle
                texto={tasa.fields.tasaRow4text}
                clr={"var(--celeste)"}
              ></Textrichtasatitle>
              <Textrichtasa
                texto={tasa.fields.tasaRow4subtext}
                clr={"var(--celeste)"}
              ></Textrichtasa>

              <br></br>
              <br></br>
              <Textrichcrecer
                texto={tasa.fields.tasaRow5text}
                clr={""}
              ></Textrichcrecer>
              <Logos cards={tasa.fields.logoEmpresas}></Logos>
              <Slidercrecer galeria={tasa.fields.galeria}></Slidercrecer>
              <br></br>
              <br></br>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
